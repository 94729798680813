<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="创建合同"
    ></el-page-header>
    <div class="select-container"></div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="created-contract"
    >
      <el-form-item label="合同编号" prop="No">
        <el-input style="width: 30%" v-model="ruleForm.No"></el-input>
      </el-form-item>
      <div class="NoStyleSucces" v-show="succesShow">
        <i class="el-icon-check iconStyle"
          >&nbsp;&nbsp;<span style="color: #999">该编号可用</span></i
        >
      </div>
      <div class="NoStyleerror" v-show="errorShow">
        <i class="el-icon-close iconStyle"
          >&nbsp;&nbsp;<span style="color: #999">该编号已被占用</span></i
        >
      </div>
      <el-form-item label="店铺名称" prop="storeName">
        <el-input style="width: 40%" v-model="ruleForm.storeName"></el-input>
      </el-form-item>
      <el-form-item label="销售人员" prop="salesman">
        <el-input style="width: 30%" v-model="ruleForm.salesman"></el-input>
      </el-form-item>
      <el-form-item label="会员名" prop="enterPersonnel">
        <el-input
          style="width: 30%"
          v-model="ruleForm.enterPersonnel"
        ></el-input>
      </el-form-item>
      <el-form-item label="会员电话" prop="phoneNo">
        <el-input style="width: 30%" v-model="ruleForm.phoneNo"></el-input>
      </el-form-item>
      <el-form-item label="合同金额" prop="contractPrice">
        <el-input-number
          v-model="ruleForm.contractPrice"
          :precision="2"
          :step="1"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="服务费" prop="serviceCharge">
        <el-input-number
          v-model="ruleForm.serviceCharge"
          :precision="2"
          :step="1"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="卡额度" prop="limit">
        <el-input-number
          v-model="ruleForm.limit"
          :precision="2"
          :step="1"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="合同图片">
        <input type="file" :disabled="disabledImage" @change="onchangemd" />
        <div class="files_list">
          <div
            class="files_list_childer"
            v-for="(item, index) in imageInfo"
            :key="index"
          >
            {{ item.imageName }}
            <span @click="files_D(item)" class="files_D el-icon-close"></span>
          </div>
        </div>
        <div class="imgList">
          <div v-for="(item, index) in imageInfo" :key="index">
            <span @click="image_D(item)" class="icon el-icon-error"></span>
            <img :src="item.imageUrl" alt="" />
          </div>
        </div>
      </el-form-item>
      <el-form-item label="创建时间" required>
        <el-col :span="11">
          <el-form-item prop="date1">
            <el-input
              style="width: 50%"
              disabled
              v-model="ruleForm.date"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import secret from "@/utils/secret.js";
export default {
  data() {
    let self = this;
    let NoEvent = function () {
      self.verify();
    };
    return {
      userKey: '',
      succesShow: false,
      errorShow: false,
      disabledImage: false,
      imageInfo: [],
      ruleForm: {
        No: "",
        storeName: "",
        salesman: "",
        phoneNo: "",
        enterPersonnel: "",
        contractPrice: "",
        serviceCharge: "",
        limit: "",
        date: "",
      },
      rules: {
        No: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
            validator: NoEvent,
          },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    this.ruleForm.date = moment().format("YYYY-MM-DD HH:mm:ss");
  },
  methods: {
    image_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    files_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    // update image
    onchangemd(e) {
      this.disabledImage = true;
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
        this.disabledImage = false;
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.imageInfo.push({
            id: this.imageInfo.length,
            imageName: files.name,
            imageUrl: this.GLOBAL.file + res.data.split('upload')[1],
          });
        });
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.disabledImage = false;
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
        this.disabledImage = false;
      }
    },
    verify() {
      let duplicateNo = this.ruleForm.No;
      let userKey = this.userKey;
      axios.post(this.GLOBAL.api + "/creat/contract", { duplicateNo,userKey }).then((res) => {
        if (res.data.statusCode === 200 && this.ruleForm.No !== "") {
          this.succesShow = true;
          this.errorShow = false;
        } else if (res.data.statusCode === 201) {
          this.errorShow = true;
          this.succesShow = false;
        }
      });
    },
    goBack() {
      this.$router.push("/homepage");
    },
    submitForm() {
      if (this.succesShow) {
        let ruleFormData = this.ruleForm;
        let userKey = this.userKey;
        const imageInfo = JSON.stringify(this.imageInfo);
        axios.post(this.GLOBAL.api + "/creat/contract", { ruleFormData, imageInfo, userKey }).then((data) => {
          if (data.data.statusCode === 400) {
            this.$message({
              message: "录入失败，请核对信息",
              type: "warning",
            });
          } else {
            this.$message({
              message: "上传成功",
              type: "success",
            });
          }
        });
      this.$router.push("/contract/list");
      } else {
        this.$message({
          message: "请正确输入合同编号",
          type: "warning",
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.succesShow = false;
      this.errorShow = false;
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.box-card {
  width: 100%;
  height: 100%;
}
.created-contract {
  margin: 5% 0 0 5%;
  position: relative;
}
.iconStyle {
  color: green;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 40%;
}
.imgList {
  display: flex;
  justify-self: flex-start;
}
.imgList div{
  width: 15%;
  margin-right: 15px;
}
.imgList img {
  width: 100%;
}
.icon {
  display: none;
}
.imgList div:hover .icon {
  display: inline-block;
} 
</style>